html, body {
    height: 100%;
} 
 
 .body  {
    font-family: 'Montserrat';
    color: #ffffff;
    background-color: #252525;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.container-private h1 {
    color: #ffffff!important;
    text-align: center;
    font-weight: 700;
    font-size: calc(0.8vw + 18px);
    letter-spacing: 0.02em;
}

.container-private h3 {
    margin-bottom: 2.5%;
    font-weight: 200;
    font-size: calc(0.4vw + 12px);
}

.container-private li {
    margin-bottom: 2%;
    font-weight: 200;
    font-size: calc(0.4vw + 12px);
}


.container-private li:nth-child(1):before {
    content: 'ACCEPTANCE OF THE TERMS';
}

.container-private li:nth-child(2):before {
    content: 'WHAT TYPE OF INFORMATION WE COLLECT?';
}

.container-private li:nth-child(3):before {
    content: 'USE OF INFORMATION WE COLLECT';
}

.container-private li:nth-child(4):before {
    content: 'SHARING INFORMATION WITH THIRD PARTIES';
}

.container-private li:nth-child(5):before {
    content: 'COOKIES AND OTHER TECHNOLOGIES';
}

.container-private li:nth-child(6):before {
    content: 'HOTJAR SERVICE';
}

.container-private li:nth-child(7):before {
    content: 'LINKS TO THIRD PARTY CONTENT';
}

.container-private li:nth-child(8):before {
    content: 'FOR HOW LONG WE RETAIN THE INFORMATION WE COLLECT?';
}

.container-private li:nth-child(9):before {
    content: 'YOUR CHOICES AND CONTROLS';
}

.container-private li:nth-child(10):before {
    content: 'HOW DO WE SAFEGUARD AND TRANSFER YOUR INFORMATION?';
}

.container-private li:nth-child(11):before {
    content: 'MINORS';
}

.container-private li:nth-child(12):before {
    content: 'TRANSFER OF DATA';
}

.container-private li:nth-child(13):before {
    content: 'DATA REMOVAL';
}

.container-private li:nth-child(14):before {
    content: 'DO NOT TRACK DISCLOSURE';
}

.container-private li:nth-child(15):before {
    content: 'AMENDMENTS';
}

.container-private li:nth-child(16):before {
    content: 'HOW TO CONTACT US';
}

.container-private li:before {
display: list-item;
list-style-image: url('../image/dot.svg');
text-decoration: underline;
text-decoration-color: #5D2D40;
text-decoration-thickness: 3px;
letter-spacing: 0.05em;
font-weight: 400;
font-size: calc(0.5vw + 16px);
margin-bottom: 1%;
}

.container-private {
    width: 80%;
    margin: auto;
}

.logo-privates {
    padding-top: 1.95em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
}