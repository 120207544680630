.absolutely {
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.readable-text, .samesamebutdifferent {
    opacity: 0.5;
}

.readable-text:hover {
    opacity: 1;
}

.gapy {
    gap: 5px;
}