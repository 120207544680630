html {
    font-family: 'Montserrat';
}

body {
    margin: 0;
    min-width: 1250px;
}

.hide {
    display: none !important;
}


.hero-black {
    padding: 10% 0% 10% 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #252525;
    will-change: transform;
    transform-style: preserve-3d;
    z-index: 3;
}

#input-search {
    border-radius: 12px 0px 0px 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(235, 235, 235, 0.06);
    padding: 12px;
    width: 80%;
}

.btn-search {
    border-radius: 0px 12px 12px 0px;
    background: #5D2D40;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(235, 235, 235, 0.03);
    cursor: pointer;
    padding: 8.5px 0px 12.1px 0px;
    width: 20%;
    font-size: 1.2em;
    font-weight: 500;
}

.mid-hero {
    width: 38%;
    min-width: 250px;
}

.search-line {
    width: 100%;
    padding-bottom: 10px;
}

.text-label, .btn-search {
    color: #fff;
    letter-spacing: 1.3px;
    margin-top: 5px;
}

.text-label {
    display: block;
}

.bottom-hero {
    color: #fff;
    font-weight: 200;
    text-align: center;
}

.tis-80 {
    width: 80%;
}

.bottom-hero h2 {
    font-size: calc(2em + 12px);
    font-weight: bold;
}

.test-black {
    overflow-x: hidden;
    min-height: 350px;
    background-color: #252525;
}

.content-test {
    color: #fff;
}

.cursor-orgaz {
    cursor: pointer;
}

.test-extension {
    background-color: #fff;
    color: #252525;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 100px;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    margin-left: -200px;
    opacity: 0;
}

.relative-wrapper {
    position: relative;
    height: 100vh;
}

.flex-hero {
    display: flex;
    overflow: hidden;
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
}

h1 {
    font-size: calc(1em + 12px);
    font-weight: 900;
    color: #252525;
}

.extension-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 150px;
    background: #5D2D40;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(235, 235, 235, 0.03);
    border-radius: 12px;
    color: #fff;
    letter-spacing: 1.3px;
    font-weight: 500;
    cursor: pointer;
    font-size: calc(0.9vw + 2.5px);
}

.gif {
    display: flex;
    min-width: 420px;
    min-height: 190px;
    width: 95%;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    height: 10%;
    font-weight: 600;
}

.gify {
    position: absolute;
    border-radius: 12px;
    box-shadow: 0px 8px 30px 3px rgba(0, 0, 0, 0.1);
}

.seperator {
    width: 100%;
}

.controls-wrapper {
    width: 100%;
}

.carousel-wrapper {
    width: 100%;
}

.organize-box {
    min-height: 50px;
    min-width: 300px;
    padding: 80px 25px 80px 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.orga-1 {
    background-image: url('../src/image/organisation-black-live-matter.png');
}

.orga-2 {
    background-image: url('../src/image/organisation-black-health.png');
    background-size: 85%;
}

.orga-3 {
    background-image: url('../src/image/organisation-nashville-community-bail-fund.jpg');
    background-size: 40%;
}

.orga-4 {
    background-image: url('../src/image/organisation-national-black-child-development-institute.png');
    background-size: 62%;
}

.orga-5 {
    background-image: url('../src/image/organisation-the-innocence-project.png');
}

.orga-1::after, .orga-2::after, .orga-3::after, .orga-4::after, .orga-5::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #5D2D40;
    opacity: 0.18;
    cursor: pointer;
    border-radius: 5px;
    transition: all ease-in-out 0.35s;
}


.orga-1::before {
    content: "blacklivesmatter.com "'  'url('../src/image/export.png');
}

.orga-2::before {
    content: "blackhealth.com "'  'url('../src/image/export.png');
}

.orga-3::before {
    content: "nashvillebailfund.org "'  'url('../src/image/export.png');
}

.orga-4::before {
    content: "nbcdi.org "'  'url('../src/image/export.png');
}

.orga-5::before {
    content: "innocenceproject.org "'  'url('../src/image/export.png');
}

.orga-1::before, .orga-2::before, .orga-3::before, .orga-4::before, .orga-5::before {
    z-index: 99;
    color: #fff;
    position: absolute;
    opacity: 0;
    transition: all ease-in-out 0.35s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    font-size: calc(0.9vw + 2.5px);
    width: 100%;
    text-align: center;
}

.orga-1:hover:after, .orga-2:hover:after, .orga-3:hover:after, .orga-4:hover:after, .orga-5:hover:after {
    opacity: 0.92;
}

.orga-1:hover:before, .orga-2:hover:before, .orga-3:hover:before, .orga-4:hover:before, .orga-5:hover:before {
    opacity: 1 !important;
}

.container-organization {
    padding: 25px 150px 50px 150px;
}

.flex-boxes {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.title-orgaz {
    text-align: initial;
    margin-bottom: 35px;
    font-size: calc(1em + 12px);
    font-weight: 900;
    color: #252525;
}

.donate-container {
    background-size: contain;
    position: relative;
    background-position: bottom center;
    background-repeat-y: no-repeat;
    height: 250px;
}

.text-donate {
    color: #fff;
    width: 40%;
    font-size: calc(0.9vw + 2.5px);
    font-weight: 300;
    opacity: 0.95;
}

.overlay {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 50px 150px 50px 150px;
    justify-content: space-between;
    top: 0;
    left: 0;
    margin: 0;
    border: none;
    width: 100%;
    height: 100%;
    background: rgba(93, 45, 64, 0.9);
}

.btn-black {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 190px;
    color: #fff;
    background: #252525;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(235, 235, 235, 0.03);
    border-radius: 12px;
    font-weight: 500;
    font-size: calc(0.9vw + 2.5px);
}

.counter-top { 
    display: flex;
    position: absolute;
    top: 10px;
    left: 15px;
    width: 12%;
    min-width: 200px;
    justify-content: space-between;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
}

.counter-top span {
    color: #fff;
    font-weight: 700;
}

.counter-top p {
    color: #fff;
    letter-spacing: 1.3px;
    font-weight: 300;
}

/******************** FOOTER *******************/
.scroll {
    width: 100vw;
    height: 15%;
    min-height: 70px;
    background-color: #252525;
    overflow: hidden;
    z-index: 1;
    margin: 0;
    padding: 0;
}

.m-scroll {
    overflow: hidden;
    white-space: nowrap;
    animation: scrollText 10s infinite linear;
    margin: 0;
    font-size: 0;
    display: inline-block;
}

 .blm-foot {
    font-size: calc(2vw + 16px);
    display: inline-block;
    min-width: 50vw;
    margin: 0;
    padding: 0;
    color: #252525;
    font-weight: 800;
    text-shadow: #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px,
        #fff 0px 0px 1px, #fff 0px 0px 1px, #fff 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
}

@keyframes scrollText {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-50%);
    }
}

.flexing {
    color: #fff;
    display: flex;
    padding: 0px 12% 0 12%;
    justify-content: space-around;
    margin-top: 25px;
    font-weight: 300;
    font-size: calc(0.5vw + 8px);
}

#input-email {
    background-color: #252525;
    border: 1px solid #fff;
    padding: 10px 70px 10px 30px;
    border-radius: 5px;
    margin-right: 15px;
    color: #fff;
}

.btn-subway {
    background-color: #fff;
    padding: 11px 15px 11px 15px;
    border-radius: 2px;
    font-weight: 600;
    margin-right: 30px;
    color: #252525;
    cursor: pointer;
}

.btn-subway:hover {
    font-weight: 700;
}

.contact-line {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

.social-media svg {
    cursor: pointer;
}

/***** Hover for the carousel arrows ******/

.left svg path, .right svg path {
    transition: fill .25s ease-in-out;
}

.filled {
    fill: #fff;
}

.right svg:hover path:nth-child(3), .left svg:hover path:nth-child(3) {
    fill: #fff;
}

.right svg:hover path:nth-child(2), .left svg:hover path:nth-child(2) {
    fill: #232323;
}

.active {
    opacity: 1;
}



/***** Hover for the footer tags ******/

.hover-1:after, .hover-2:after, .hover-3:after, .hover-4:after, .hover-5:after {
    content: '';
    width: 0px;
    height: 2px;
    display: block;
    background: #fff;
    transition: 300ms;
}

.hover-1:hover:after, .hover-2:hover:after, .hover-3:hover:after, .hover-4:hover:after, .hover-5:hover:after {
    width: 100%;
}

.twitter path, .twitter circle, .facebook path, .facebook circle, .instagram path, .instagram circle {
    transition: fill .25s ease-in-out;
}

.twitter:hover path, .facebook:hover path, .instagram:hover path {
    fill: #252525;
}

.twitter:hover circle, .facebook:hover circle, .instagram:hover circle {
    fill: #fff;
}

.copyright-line {
    color: #fff;
    opacity: 0.85;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
}